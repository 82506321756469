import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import { Payrolls,
  Payroll,
  PeriodRule,
  BasicFee,
  Rule,
  Withdraw,
  Tier,
  BonusFee,
  BonusData,
  PercentageBonusData,
  Setting,
  CourierAttendences,
  CourierAttendance,
  CourierAttendanceDetails,
  CourierAttendanceCourierData,
  CourierAttendancePresenceDetail,
  CourierAttendanceSummary,
  CourierAttendanceRequestDetail,
  CourierAttendanceRequestHistory,
  CourierAttendanceRequest,
  CourierAttendanceRequestMeta,
  CourierAttendanceRequestMetaProofs,
  CourierAttendanceRequestMetaProofsData,
  ManualAdjustBasicFees,
  ManualAdjustBasicFee,
  ManualAdjustBasicFeeCourierData,
  ManualAdjustBasicFeeHistories,
  ManualAdjustBasicFeeHistory,
  ManualAdjustBasicFeeHistoryDetail,
  ManualAdjustBasicFeeHistoryDetailData,
  ManualAdjustBasicFeeHistoryDetailSummary,
  ManualAdjustBasicFeeHistoryDetailDataUpload
} from '@/domain/entities/Payroll'
import { Utils } from '@/app/infrastructures/misc'
import { RulesResponse } from '@/data/responses/contracts/ManageDataMaster'
import { UploadBulkBalanceAmount } from '@/domain/entities/ManualAdjustmentBalance'

export class PayrollMapper extends BaseResponseMapper {
  public convertPayrollFromApi(result: AxiosResponse): Payrolls {
    const {
      data: { data, pagination },
    } = result

    return new Payrolls(
      new Pagination(
        pagination.page,
        pagination.limit,
        pagination.total_data,
      ),
      data.map(
        (item: Record<string, string | number>) =>
          new Payroll(
            item.id as number,
            item.start_at as string,
            item.end_at as string,
            item.status as string,
          )
      )
    )
  }

  public convertDetailPeriodRuleFromApi(result: AxiosResponse): PeriodRule {
    const {
      data: { data },
    } = result
    
    const productTypes = data.basic_fee.product_type || [];

    return new PeriodRule(
      data.id,
      data.basic_fee_id,
      new BasicFee (
        data.basic_fee.calculation_method,
        data.basic_fee.multiply_type,
        [...productTypes, "vippack"].map((product) => product.toLowerCase()).filter((product, index, arr) => {
          return arr.indexOf(product) === index
        }),
        data.basic_fee.rules.map(
          (rule: RulesResponse) => {
          return new Rule(
            rule.calculation_method,
            (rule.delivery_tier).map((dt) => {
              return new Setting(
                dt.prefix,
                dt.setting?.map((setting) => {
                  return new Tier(
                    setting.fee,
                    setting.min_weight,
                    setting.multiply_type,
                    setting.parking_fee,
                    setting.cod_multiply_type,
                    setting.cod_fee

                  ) 
                }),
              )
            }),
            (rule.pickup_tier).map((dt) => {
              return new Setting(
                dt.prefix,
                dt.setting?.map((setting) => {
                  return new Tier(
                    setting.fee,
                    setting.min_weight,
                    setting.multiply_type,
                    setting.parking_fee,
                    setting.cod_multiply_type,
                    setting.cod_fee
                  ) 
                }),
              )
            }),
            rule.product_type as string
          )
        })
      ),
      data.withdraw_id,
      new Withdraw (
        data.withdraw.min_payout_amount,
        data.withdraw.min_saldo,
        data.withdraw.payout_fee
      ),
      data.bonus_fee_id,
      new BonusFee (
        data.bonus_fee.bonus_recipient,
        data.bonus_fee.captain_bonus,
        data.bonus_fee.captain_bonus_fee,
        data.bonus_fee.captain_bonus_options,
        data.bonus_fee.insurance,
        data.bonus_fee.percentage_bonus,
        data.bonus_fee.percentage_bonus_data.map((tier: Record<string, number>) => {
          return new PercentageBonusData(
            tier.adjustment,
            tier.bonus_max_limit,
            tier.percentage
          )
        }),
      ),
      data.updated_by,
      data.updated_at,
      data.created_at,
      data.created_by,
    )
  }

  convertManageCourierAttendanceListFromApi(
    result: AxiosResponse
  ): CourierAttendences {
    const {
      data: { data, pagination, summary },
    } = result

    return new CourierAttendences(
      Utils.toInstance(new Pagination(), pagination),
      data.map((attendance: Record<string, string | number>) => {
        return new CourierAttendance(
          <number>attendance.courier_id,
          <string>attendance.courier_name,
          <number>attendance.attendance_total,
          <number>attendance.request_attendance,
          <number>attendance.day_total,
          <string>attendance.courier_3lc,
          <string>attendance.shuttle_id,
          <string>attendance.shuttle_name,
          <string>attendance.shuttle_prefix,
        )
      }),
      summary?.summary_attendance || 0 
    )
  }

  convertManageCourierAttendanceDetailFromApi(
    result: AxiosResponse
  ): CourierAttendanceDetails {
    const {
      data: {data_courier, total_day_presence, total_day_of_month, presence_detail, summary, shuttle_id, shuttle_name, shuttle_prefix}
    } = result

    return new CourierAttendanceDetails(
      Utils.toInstance(new CourierAttendanceCourierData(), data_courier),
      total_day_presence,
      total_day_of_month,
      presence_detail.map((presence: Record<string, string>) => {
        return new CourierAttendancePresenceDetail(presence.date_presence, presence.status)
      }),
      Utils.toInstance(new CourierAttendanceSummary(), summary),
      shuttle_id,
      shuttle_name,
      shuttle_prefix
    )
  }

  convertManualBasicFeeUploadBulkyTemplateFromApi(
    result: AxiosResponse
  ): string {
    const {
      data: { data: { url } },
    } = result

    return url
  }

  convertManageCourierAttendanceRequestDetailFromApi(
    result: AxiosResponse
  ): CourierAttendanceRequestDetail {
    const {
      data
    } = result

    return new CourierAttendanceRequestDetail(
      data.status,
      data.attendance_total,
      data.day_total,
      data.absence_date,
      data.presence_id,
      new CourierAttendanceCourierData(
        data.courier.courier_id,
        data.courier.courier_name,
        data.courier.courier_phone,
        data.courier.courier_3lc
      ),
      data.attendance_note,
      data.histories.map((history: Record<string, string>) => {
        return new CourierAttendanceRequestHistory(
          history.username,
          history.note,
          history.status,
          history.created_at
        )
      }),
      data.attendance ? new CourierAttendanceRequest(
        data.attendance?.attendance_id,
        data.attendance?.status,
        data.attendance?.attendance_date,
        new CourierAttendanceRequestMeta(
          new CourierAttendanceRequestMetaProofs(
            data.attendance.meta.proofs.admin.map((proof: Record<string, string>) => {
              return new CourierAttendanceRequestMetaProofsData(proof.file_name, proof.url)
            }),
            data.attendance.meta.proofs.courier.map((proof: Record<string, string>) => {
              return new CourierAttendanceRequestMetaProofsData(proof.file_name, proof.url)
            })
          )
        )
      ) : new CourierAttendanceRequest()
      )
    }

  convertManualAdjustBasicFeeListFromApi(
    result: AxiosResponse
  ): ManualAdjustBasicFees {
    const {
      data: { data, pagination },
    } = result

    return new ManualAdjustBasicFees(
      data.map(
        (
          upload: Record<
            string,
            string | number | string[] | Record<string, string | number>
          >
        ) => {
          const courierData = <Record<string, string | number>>upload.courier
          return new ManualAdjustBasicFee(
            <number>upload.id,
            <string>upload.activity,
            <number>upload.amount,
            <string>upload.approved_at,
            <string>upload.approved_by,
            new ManualAdjustBasicFeeCourierData(
              <number>courierData.id,
              <string>courierData.fullname,
              <string>courierData.phone_number,
              <string>courierData.status,
              <string>courierData.type
            ),
            <string>upload.created_at,
            <string>upload.created_by,
            <string>upload.filename,
            <string>upload.insert_type,
            <string>upload.notes,
            <string>upload.status,
            <string>upload.type,
            <string>upload.package_id,
            <string>upload.task_type,
            <string[]>upload.meta
          )
        }
      ),
      Utils.toInstance(new Pagination(), pagination)
    )
  }

  convertManualAdjustBasicFeeHistoryListFromApi(
    result: AxiosResponse
  ): ManualAdjustBasicFeeHistories {
    const {
      data: { data, pagination }
    } = result

    return new ManualAdjustBasicFeeHistories(
      data.map((history: Record<string, string | number>) => {
        return new ManualAdjustBasicFeeHistory(
          <number>history.id,
          <string>history.created_at,
          <string>history.created_by,
          <string>history.filename,
          <string>history.deleted_at,
          <string>history.deleted_by,
          <string>history.filepath,
          <string>history.reason,
          <string>history.status_approval,
          <string>history.status_id,
          <string>history.updated_at,
          <string>history.updated_by
        )
      }),
      Utils.toInstance(new Pagination(), pagination)
    )
  }

  convertManualAdjustBasicFeeHistoryDetailFromApi(
    result: AxiosResponse
  ): ManualAdjustBasicFeeHistoryDetail {
    const {
      data: { data, pagination }
    } = result

    return new ManualAdjustBasicFeeHistoryDetail(
      Utils.toInstance(new Pagination(), pagination),
      new ManualAdjustBasicFeeHistoryDetailData(
        data.filename,
        data.filepath,
        data.created_by,
        data.created_at,
        data.status,
        data.updated_at,
        data.updated_by,
        data.reason,
        new ManualAdjustBasicFeeHistoryDetailSummary(
          data.summary.total_row_valid,
          data.summary.total_row_invalid,
          data.summary.total_courier,
          data.summary.total_courier_valid,
          data.summary.total_courier_invalid,
          new UploadBulkBalanceAmount(
            data.summary.total_amount_valid.addition,
            data.summary.total_amount_valid.reduction,
          ),
          new UploadBulkBalanceAmount(
            data.summary.total_amount_invalid.addition,
            data.summary.total_amount_invalid.reduction,
          )
        ),
        data.data_upload.map((upload: string) => {
          return Utils.toInstance(
            new ManualAdjustBasicFeeHistoryDetailDataUpload(),
            upload
          )
        }),
        undefined,
        undefined,
        data.filepath_data
      )
    )
  }

  convertUploadBulkyManualAdjustBalanceListFromApi(
    result: AxiosResponse
  ): ManualAdjustBasicFeeHistoryDetail {
    const {
      data: { data: {data_upload, pagination} }
    } = result

    return new ManualAdjustBasicFeeHistoryDetail(
      Utils.toInstance(new Pagination(), pagination),
      new ManualAdjustBasicFeeHistoryDetailData(
        data_upload.filename,
        data_upload.filepath,
        data_upload.created_by,
        data_upload.created_at,
        data_upload.status,
        data_upload.updated_at,
        data_upload.updated_by,
        data_upload.reason,
        new ManualAdjustBasicFeeHistoryDetailSummary(
          data_upload.summary.total_row_valid,
          data_upload.summary.total_row_invalid,
          data_upload.summary.total_courier,
          data_upload.summary.total_courier_valid,
          data_upload.summary.total_courier_invalid,
          new UploadBulkBalanceAmount(
            data_upload.summary.total_amount_valid.addition,
            data_upload.summary.total_amount_valid.reduction,
          ),
          new UploadBulkBalanceAmount(
            data_upload.summary.total_amount_invalid.addition,
            data_upload.summary.total_amount_invalid.reduction,
          )
        ),
        data_upload.rows.map((upload: string) => {
          return Utils.toInstance(
            new ManualAdjustBasicFeeHistoryDetailDataUpload(),
            upload
          )
        }),
        data_upload.data_valid_status,
        Boolean(pagination)
      )
    )
  }
}
